const HeaderSection = ({name}) => {
    return (
      <div>
        <div className="slider-area2 mb-5">
          <div className="slider-height2 d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="hero-cap hero-cap2 pt-5 text-center">
                    <h2>{name}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default HeaderSection;
  