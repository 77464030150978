import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";

const SelectEmployee = ({ setGoSteps }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  const Employees = [
    {
      id: 1,
      title: "Employee 1",
      img: "images/barber.webp",
    },
    {
      id: 2,
      title: "Employee 2",
      img: "images/barber.webp",
    },
    {
      id: 3,
      title: "Employee 3",
      img: "images/barber.webp",
    },
    {
      id: 4,
      title: "Employee 4",
      img: "images/barber.webp",
    },
  ];

  return (
    <>
      <div className="row justify-content-center mb-5 mx-1">
        <div className="col-md-10 col-lg-9 col-xxl-7 col-12 pt-2 pb-4 text-center custom-border waitlist-height waitlist-box">
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(1)}
                className=" gj-cursor-pointer back-btn fs-3 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center pb-3">
              <h2 className=" fw-bold ">Select Staff</h2>{" "}
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 overflow-auto mobile-height">
              <div className="row justify-content-center">
                {Employees.map((item) => (
                  <div
                    key={item.id}
                    className={`form-check category-border col-xl-3 col-lg-5 col-5 px-0  mx-2 my-3 ${
                      selectedItem === item.id ? "service-bg" : ""
                    }`}
                  >
                    <div
                      onClick={() => handleItemClick(item.id)}
                      className="row mx-0"
                    >
                      <div className="col-12 py-3 text-center">
                        <img src={item.img} className="img-fluid rounded" />
                      </div>
                      <div className="col-12 py-1 text-center">
                        <p className="fs-6 pb-2 fw-bold m-0 user-select-none d-inline-block">
                          {item.title}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button
            onClick={() => setGoSteps(3)}
            className="btn btn--tra-black hover--black mt-4"
          >
            Reservation
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectEmployee;
