import { AiOutlineArrowLeft } from "react-icons/ai";

const UserInfo = ({ setGoSteps }) => {
  return (
    <>
      <div className="row justify-content-center mx-1">
        <div className="custom-border col-12 col-md-10 col-lg-6 waitlist-height waitlist-box">
          <div className="row justify-content-center">
            <div className="col-12 text-center mb-5">
              <div className="row mt-2">
                <div className="col-2 text-start">
                  <AiOutlineArrowLeft
                    style={{ background: "var(--primary-color)" }}
                    onClick={() => setGoSteps(3)}
                    className=" gj-cursor-pointer back-btn fs-3 p-1 rounded"
                  ></AiOutlineArrowLeft>
                </div>
                <div className="col-8 text-center pb-3">
                  <h3 className=" fw-bold pt-4">Information</h3>{" "}
                </div>
              </div>
            </div>
            <div className="col-10">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="text"
                className="form-control  border border-black rounded-0 p-2"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="col-10 my-3">
              <label htmlFor="exampleInputEmail1" className="form-label mb-0">
                Enter Your Phone Number
              </label>
            </div>
            <div className="col-10 mb-3 ">
              <div className="input-group">
                <span className="input-group-text rounded-0" id="basic-addon1">
                  +961
                </span>
                <input
                  type="text"
                  className="form-control border border-black rounded-0 p-2"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
            </div>
            <div className="col-8 col-lg-6 mb-3 text-center">
              <button
                onClick={() => setGoSteps(5)}
                className="btn btn--tra-black hover--black mt-4 "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
