import React from "react";
import { Link } from "react-router-dom";

const Services = (props) => {
  const { isInServices } = props;
  return (
    <section style={{ backgroundColor: "#141412" }} id="services-2" className="py-default services-section division">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-10 col-xl-9">
            <div className="txt-block text-center">
              <h2 className="h2-title mb-5">Your Secret Place of Beauty</h2>
            </div>
          </div>
        </div>
        <div className="sbox-2-wrapper text-center">
          <div className="row  row-cols-1 row-cols-md-2 row-cols-lg-4">
            <div  className="col">
              <div className="sbox-2 sb-1 wow fadeInUp">
                <div
                
                  className="sbox-ico ico-65"
                  style={{ color: "var(--primary-color)" }}
                >
                  <span className="flaticon-facial-treatment"></span>
                </div>

                <div className="sbox-txt">
                  <h5 className="h5-lg">Facials</h5>
                </div>
              </div>
            </div>

            <div  className="col">
              <div className="sbox-2 sb-2 wow fadeInUp">
                <div
                  className="sbox-ico ico-65"
                  style={{ color: "var(--primary-color)" }}
                >
                  <span className="flaticon-wax"></span>
                </div>

                <div className="sbox-txt">
                  <h5 className="h5-lg">Waxing</h5>
                </div>
              </div>
            </div>

            <div  className="col">
              <div className="sbox-2 sb-3 wow fadeInUp">
                <div
                  className="sbox-ico ico-65"
                  style={{ color: "var(--primary-color)" }}
                >
                  <span className="flaticon-cosmetics"></span>
                </div>

                <div className="sbox-txt">
                  <h5 className="h5-lg">Make-Up</h5>
                </div>
              </div>
            </div>

            <div  className="col">
              <div className="sbox-2 sb-4 wow fadeInUp">
                <div
                  className="sbox-ico ico-65 "
                  style={{ color: "var(--primary-color)" }}
                >
                  <span className="flaticon-nail-polish"></span>
                </div>

                <div className="sbox-txt">
                  <h5 className="h5-lg">Nails</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isInServices && (
          <div className="row mt-5">
            <div className="col">
              <div className="more-btn">
                <a href="#service-head">
                  <Link
                    to="/service"
                    className="btn btn--tra-black hover--black"
                  >
                    See All Services
                  </Link>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Services;
