import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Home from "../Pages/Home";
import Gallery from "../Pages/Gallery";
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Service from "../Pages/ServicePage";
import Confirmation from "../Pages/Confirmation";
import Preloader from "../Component/Preloader";
import WaitlistPage from "../Pages/WaitlistPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: [<Main />, <Preloader />],

    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/service",
        element: <Service />,
      },
      {
        path: "/confirmation",
        element: <Confirmation />,
      },
      {
        path: "/waitlistPage",
        element: <WaitlistPage />,
      },
    ],
  },
]);
