import React, { useEffect } from "react";
import Services from "../Component/Services";
import OurLocation from "../Component/OurLocation";
import HeaderSection from "../Component/HeaderSection";
import ServicePrice from "../Component/ServicePrice";

const ServicePage = () => {
  useEffect(() => {
    const element = document.getElementById("service-head");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <div id="service-head"><HeaderSection name="Services" /></div>
      <ServicePrice />
      <Services isInServices={true} />
      <OurLocation />
    </>
  );
};

export default ServicePage;
