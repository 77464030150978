import React from "react";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  return (
    <section id="hero-10" className="bg--fixed hero-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="color--white">
              <h2 className="display-1">The Salon That Gives You Style</h2>

              <p className="py-4 mb-0">
                Aliqum mullam blandit tempor diam sapien gravida donec ipsum
                porta justo velna auctor congue
              </p>

              <Link href="#lnk-1" className="btn btn--tra-black hover--black">
                View Salon Menu
              </Link>
            </div>
          </div>

          <div className="col-lg-5 mt-lg-0 pt-5 align-self-end ">
            <div className="hero-10-schedule color--white text-end">
              <h5 className="h5-lg">Opening Hours</h5>

              <p>
                Mon - Fri: <span>9:00AM - 8:00PM</span>
              </p>
              <p>
                Sat - Sun: <span>9:00AM - 6:30PM</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
