import React from "react";
import HeaderSection from "../Component/HeaderSection";
import AboutText from "../Component/AboutText";
import Services from "../Component/Services";
import OurTeam from "../Component/OurTeam";

const About = () => {
  return (
    <>
      <HeaderSection name="About" />
      <AboutText />
      <Services isInServices={false}/>
      <OurTeam />
    </>
  );
};

export default About;
