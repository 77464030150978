import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa6";
import { FaLevelUpAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer id="footer-3" className=" footer division">
      <div className="container text-center">
        <hr />
        <div className="bottom-footer">
          <div className="row justify-content-center">
            <div className="col">
              <div className="footer-copyright row text-white">
                <div className="col-6">
                  <p>
                    &copy; 2023 Reine -
                    <span>
                      <a
                        style={{ color: "var(--primary-color)" }}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.abss.tech/"
                      >
                        <span> ABSS</span>
                      </a>
                    </span>
                  </p>
                </div>
                <div className="col-6">
                  <Link style={{ color: "var(--primary-color)" }}>
                    <FaFacebook className="fs-3" />
                  </Link>
                  <Link
                    style={{ color: "var(--primary-color)" }}
                    className="mx-4"
                  >
                    <FaInstagram className="fs-3" />
                  </Link>
                  <Link style={{ color: "var(--primary-color)" }}>
                    <FaTwitter className="fs-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* back to top button is here */}
      <div>
        <a id="back-top" className="text-black" title="Go to Top" href="#">
          <FaLevelUpAlt style={{ color: "var(--secondary-color)" }} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
