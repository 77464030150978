import React from "react";
import HeaderSection from "../Component/HeaderSection";

const WaitlistPage = () => {
  return (
    <>
      <HeaderSection name="Waitlist Page" />
      <div className="container">
        <div className="row justify-content-center py-default mx-1">
          <div className=" col-md-10 col-lg-9 col-xxl-6 col-12 custom-border">
            <div className="row">
              <div className="col-12">
                <p className="text-center mb-0">BarberShop</p>
                <h2 className="text-center mb-0">Waitlist</h2>
                <p className="text-center mb-0 fw-semibold">
                  3 waiting Estimated wait 1 min
                </p>
              </div>
            </div>

            <div className="d-sch-table">
              <div className="de-separator"></div>
              <div className="d-col d-flex align_items-center  justify-content-between">
                <div className="d-content id-color fs-5">1</div>
                <div className="d-content id-color fs-5">Oussama</div>
                <div className="d-content id-color fs-5">25 min</div>
              </div>
              <div className="d-col d-flex align_items-center justify-content-between">
                <div className="d-content id-color fs-5">3</div>
                <div className="d-content id-color fs-5">Mohiuddin</div>
                <div className="d-content id-color fs-5">35 min</div>
              </div>
              <div className="d-col d-flex align_items-center justify-content-between">
                <div className="d-content id-color fs-5">4</div>
                <div className="d-content id-color fs-5">Ahad</div>
                <div className="d-content id-color fs-5">50 min</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitlistPage;
