import { useState } from "react";
import OtpInput from "react-otp-input";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Otp = ({ setGoSteps }) => {
  const [otp, setOtp] = useState("");
  return (
    <>
      <div className="row justify-content-center mx-1">
        <div
          id="dialog"
          className="custom-border col-md-10 col-lg-7 col-xl-6 col-12 pt-2 pb-5 waitlist-height waitlist-box"
        >
          <div className="row d-flex justify-content-center">
            <div className="row">
              <div className="col-2 text-start">
                <AiOutlineArrowLeft
                  style={{ background: "var(--primary-color)" }}
                  onClick={() => setGoSteps(4)}
                  className=" gj-cursor-pointer back-btn fs-3 p-1 rounded"
                ></AiOutlineArrowLeft>
              </div>
              <div className="col-8 text-center pb-3">
                <h4 className="fw-bold">Please enter the verification code</h4>
              </div>
            </div>
          </div>

          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType={true}
            containerStyle="custom-otp-container"
            inputStyle="custom-otp-input"
            renderInput={(props) => <input {...props} />}
          />
          <div className="text-center">
            <Link to="/confirmation">
              <button
                onClick={() => setGoSteps(6)}
                className="btn btn--tra-black hover--black mt-4 "
              >
                Verify
              </button>
            </Link>
          </div>

          <div className="text-center mt-5">
            Didnt receive the code?
            <br />
            <Link className="gj-cursor-pointer">Send code again</Link>
            <br />
            <Link className="gj-cursor-pointer">Change phone number</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
