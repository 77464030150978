import React from "react";

const AboutText = () => {
  return (
    <section className="py-default ct-01 content-section division">
      <div className="container">
        <div className="row  d-flex align-items-center">
          <div className="col-lg-6 order-last order-lg-2">
            <div className="txt-block left-column wow fadeInRight">
              <span className="section-id mt-4">Mind, Body and Soul</span>
              <h2 className="h2-md">Luxury salon where you will feel unique</h2>
              <p className="mb-0">
                Sagittis congue augue egestas integer velna purus purus magna
                libero suscipit and egestas magna aliquam ipsum vitae purus
                justo lacus ligula ipsum primis cubilia donec undo augue luctus
                vitae egestas a molestie donec libero sapien dapibus congue
                tempor undo quisque and fusce cursus neque blandit fusce aliquam
                nulla lacinia
              </p>
            </div>
          </div>
          <div className="col-lg-6 order-first order-lg-2">
            <div className="img-block left-column wow fadeInRight ">
              <img
                className="img-fluid w-100 rounded-4"
                src="images/team/team1.webp"
                alt="content-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutText;
