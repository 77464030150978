import React, { useEffect, useState } from "react";
import HeaderSection from "../Component/HeaderSection";

const images = [
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery2.webp" },
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  useEffect(() => {
    const element = document.getElementById("gallery-head");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div id="gallery-head">
        {" "}
        <HeaderSection name="Gallery" />
      </div>

      <div className="my-5 mx-3 ">
        <div className="row g-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="col-md-6 col-lg-3 col-12 "
              onClick={() => openModal(image.img)}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className=" de-image-hover rounded">
                <span className="dih-overlay"></span>
                <img src={image.img} className="w-100" alt="..." />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onClick={closeModal}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl extra-large-modal mx-auto">
          <div
            className="modal-content"
            style={{ backgroundColor: "var(--primary-color)" }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-body">
              {selectedImage && (
                <img
                  className="justify-content-center align-content-center img-fluid"
                  style={{ width: "100%", objectFit: "cover" }}
                  src={selectedImage}
                  alt="Selected"
                />
              )}
            </div>

            <button
              type="button"
              className="btn header-btn text-black text-end "
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
