import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { useState } from "react";
const SelectService = ({ setGoSteps }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  const Services = [
    {
      id: 1,
      title: "Item 1",
      description: "haircut + shaving + scrub + mask + beard trim",
      time: 30,
      price: 100,
    },
    {
      id: 2,
      title: "Item 2",
      description: "haircut + shaving + scrub + mask + beard trim",
      time: 45,
      price: 150,
    },
    {
      id: 3,
      title: "Item 3",
      description: "haircut + shaving + scrub + mask + beard trim",
      time: 60,
      price: 200,
    },
    {
      id: 4,
      title: "Item 4",
      description: "haircut + shaving + scrub + mask + beard trim",
      time: 60,
      price: 200,
    },
    {
      id: 5,
      title: "Item 5",
      description: "haircut + shaving + scrub + mask + beard trim",
      time: 60,
      price: 120,
    },
    {
      id: 6,
      title: "Item 6",
      description: "haircut + shaving + scrub + mask + beard trim",
      time: 60,
      price: 200,
    },
  ];

  return (
    <>
      <div>
        <div className="row justify-content-center mb-5 mx-1">
          <div className=" col-md-10 col-lg-9 col-xxl-7 col-12 py-5 pt-2 text-center custom-border waitlist-height waitlist-box">
            <div className="row">
              <div className="col-2 text-start">
                <AiOutlineArrowLeft
                  style={{ background: "var(--primary-color)" }}
                  onClick={() => setGoSteps(0)}
                  className=" gj-cursor-pointer back-btn fs-3 p-1 rounded"
                ></AiOutlineArrowLeft>
              </div>
              <div className="col-8 text-center pb-3">
                <h2 className="fw-bold">Services</h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 overflow-auto mobile-height">
                <div className="row justify-content-center">
                  {Services.map((item) => (
                    <div
                      key={item.id}
                      className={`form-check category-border col-lg-10 col-11 px-0 my-3 ${
                        selectedItem === item.id ? "service-bg" : ""
                      }`}
                    >
                      <div
                        onClick={() => handleItemClick(item.id)}
                        className="mx-1"
                      >
                        <div className=" py-2 text-start">
                          <p className="fs-4 fw-bold mb-0  user-select-none">
                            {item.title}
                          </p>
                          <p className="my-0 user-select-none">
                            {item.description}
                          </p>
                          <p className="m-0 user-select-none">
                            <span className="me-3">
                              <BiTimeFive></BiTimeFive> {item.time} minutes
                            </span>

                            <span>${item.price}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-8 col-lg-6 mx-auto">
              <button
                onClick={() => setGoSteps(2)}
                className="btn btn--tra-black hover--black mt-4 "
              >
                Reservation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SelectService;
