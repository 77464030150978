import React from "react";

const OurLocation = () => {
  return (
    <section className="py-default  content-section division">
      <div className="container text-center">
        <div className="mb-5 ct-table content-section division">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <div className="left-column wow fadeInRight">
                  <span className="section-id">Location</span>

                  <div className="txt-table right-column wow fadeInLeft">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Address</td>
                          <td> - </td>
                          <td className="text-end">Lebanon</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td> - </td>
                          <td className="text-end">+961 7887 1649</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-lg-0 mt-5">
                <span className="section-id">Working Hours</span>
                <div className="txt-table right-column wow fadeInLeft">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Mon – Friday</td>
                        <td> - </td>
                        <td className="text-end">10:00 AM - 9:00 PM</td>
                      </tr>
                      <tr>
                        <td>Sat - Sun</td>
                        <td>-</td>
                        <td className="text-end">10:00 AM - 5:00 PM</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurLocation;
