import React from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { TfiMenuAlt } from "react-icons/tfi";
import { RxCrossCircled } from "react-icons/rx";
import HeaderSection from "../Component/HeaderSection";
import { Link } from "react-router-dom";

const Confirmation = () => {
  return (
    <>
      <HeaderSection name="Confirmation" />
      <div id="confirm" className="container py-default">
        <div className="row justify-content-center mx-1">
          <div className="col-md-10 py-2 mb-5 col-lg-9 col-xxl-6 col-12 custom-border">
            <div className="row">
              <div className="col-12 pt-3">
                <p className="text-center mb-0">BarberShop</p>
                <div className="text-center py-3">
                  <IoMdCheckmarkCircleOutline
                    style={{ fontSize: "150px", color: "var(--primary-color)" }}
                  />
                </div>
                <h2
                  className="text-center fw-semibold mb-3
                "
                >
                  Thanks for waiting!
                </h2>
                <p className="text-center mb-0 fw-semibold">
                  Stay on this page to get notified when it's your turn.
                </p>
              </div>
            </div>
            <div className="row justify-content-center text-center mt-4">
              <div className="col-6">
                <p className="mb-0">Place in line</p>
                <p>3</p>
              </div>
              <div className="col-6">
                <p className="mb-0">Estimate Wait</p>
                <p>55-65 min</p>
              </div>
            </div>
            <div className="row mt-5  justify-content-center text-center">
              <div className="col-lg-3 pb-3 col-6">
                <p className="mb-0 ">Name</p>
                <p className="mb-0 fw-bolder pb-1">Test</p>
              </div>
              <div className="col-lg-3 pb-3  col-6">
                <p className="mb-0 ">Phone</p>
                <p className="mb-0 fw-bolder pb-1">78 871 649</p>
              </div>
              <div className="col-lg-3 pb-3  col-6">
                <p className="mb-0 ">Staff</p>
                <p className="mb-0 fw-bolder pb-1">mahmoud</p>
              </div>
              <div className="col-lg-3 pb-3  col-6">
                <p className="mb-0 ">Services</p>
                <p className="mb-0 fw-bolder">haircut beard</p>
              </div>
            </div>
            <hr />
            <div className="row pb-4 text-center justify-content-center align-items-center">
              <div className="col-6">
                <Link to="/waitlistPage" className="my-3 fw-bolder">
                  <TfiMenuAlt /> View Waitlist
                </Link>
              </div>
              <div className="col-6">
                <Link to="/" className="mb-0 fw-bolder">
                  <RxCrossCircled /> Leave Waitlist
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
